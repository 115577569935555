<template>
  <div class="isContent">
    <isHeadlist></isHeadlist>
    <el-row :gutter="15">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div
            class="artcle-wrap-pic artcle-pingce-pic"
            :style="{ backgroundImage: 'url(' + isNewcen.thumb + ')' }"
          >
            <!-- 
            :style="{ backgroundImage: 'url(' + imgUrl + ')' }"     
            :style="{ backgroundImage: 'url(' + isurl + isNewcen.thumb + ')' }" -->
            <!-- style="background-image:url(http://cms-bucket.ws.126.net/2019/07/15/93f4bd077ab0492fb0b00d0d90a61857.png)"-->
            <div class="g-w1200 posr">
              <h1 class="article-h1">
                <!-- 次时代“HD如龙”的最终弹——《如龙5：实现梦想者》评测
                 -->
                {{ isNewcen.title }}
              </h1>
              <div class="art-about-new">
                <dl class="m-pingce">
                  <dd>
                    <p class="p1">
                      {{ isNewcen.description }}
                    </p>
                    <p class="p2" style="text-align: left;">
                      {{ (isNewcen.add_time * 1000) | formatDate }}
                    </p>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row
      style="padding:10px;"
      type="flex"
      class="row-bg"
      justify="center"
      :gutter="15"
    >
      <el-col :xs="24" :sm="24" :md="17" :lg="17" :xl="17" justify="center">
        <!-- <el-col :span="12" style="    border-bottom: 1px #c3c3c3 solid;">
          <div class="dontai">新闻动态</div>
        </el-col>
        <el-col :span="12" style="    border-bottom: 1px #c3c3c3 solid;">
          <div class="golist" @click="isgolist">返回列表</div>
        </el-col>-->
        <el-col :xs="24" :sm="24" :md="17" :lg="17" :xl="17" class="centitle">
          <!-- <div class="title">新闻标题</div>
          <div class="time">时间：2019-6-1 23：15</div>-->
          <div class="cent" v-html="isNewcen.content">
            {{ isNewcen.content }}
            <!-- 团队是指拥有共同目标，并且具有不同能力的一小群人有意识的协调行为或执行力的系统，这群人就如同人的五官一样，共同协作维持一个人的生存，缺一不可。我们是一支专业的团队。我们的成员来自国内知名游戏公司的一线骨干，拥有多年的移动游戏开发经验。
            我们是一支年轻的团队。我们的平均年龄仅有27岁，充满了朝气和创新精神。我们是一支注重品质的团队。我们坚信，始终如一对品质的追求能创造出更多好玩有趣的游戏。我们是一支有梦想的团队。我们来自五湖四海，因为一个共同的梦想：做出能为玩家带来快乐的好游戏。我们是一支年轻的团队。我们的平均年龄仅有27岁，充满了朝气和创新精神。我们是一支注重品质的团队。我们坚信，始终如一对品质的追求能创造出更多好玩有趣的游戏。我们是一支有梦想的团队。我们来自五湖四海，因为一个共同的梦想：做出能为玩家带来快乐的好游戏。
            我们是一支年轻的团队。我们的平均年龄仅有27岁，充满了朝气和创新精神。我们是一支注重品质的团队。我们坚信，始终如一对品质的追求能创造出更多好玩有趣的游戏。我们是一支有梦想的团队。我们来自五湖四海，因为一个共同的梦想：做出能为玩家带来快乐的好游戏。 -->
          </div>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="7"
          :lg="7"
          :xl="7"
          class="hidden-md-and-down"
        >
          <div class="groom">精彩推荐</div>
          <div class="isgroomcen">
            <ul>
              <li v-for="(item, index) in isNews" :key="index">
                <div
                  v-if="item.is_hot == 0"
                  style="text-align: left;"
                  @click="gotocen(item.id)"
                >
                  {{ item.title }}
                </div>
              </li>
            </ul>
          </div>
        </el-col>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import isHeadlist from "@/components/Headlist.vue";
import { formatDate } from "@/common/date.js";

export default {
  name: "isContent",
  components: {
    isHeadlist
  },
  filters: {
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    }
  },
  data() {
    return {
      isNewcen: "",
      isurl: "https://www.sparkgame.com.cn/",
      isNews: [],
      // topimg: require('../assets/2280-top.png'),
      imgUrl: require("../assets/skjk.png")
    };
  },
  methods: {
    gotocen(id) {
      // this.$router.push({ name: "isContent", params: { isid: id } });
      localStorage.isid = id;
      location.reload();
      console.log(1);
    },
    isgolist() {
      console.log(1);
      this.$router.push({ path: "/List" });
    },
    //获取当前页面
    renderingCenr(id) {
      this.$axios({
        method: "post",
        url: "https://www.sparkgame.com.cn/api.php",
        data: this.qs.stringify({
          act: "get_detail",
          mode: "news",
          id: id
        })
      })
        .then(response => {
          console.log(response.data.result);
          this.isNewcen = response.data.result; //返回的数据
        })
        .catch(error => {
          console.log(error); //请求失败返回的数据
        });
    },
    //渲染资讯
    renderingNews() {
      this.$axios({
        method: "post",
        url: "https://www.sparkgame.com.cn/api.php",
        data: this.qs.stringify({
          act: "get_list",
          mode: "news"
        })
      })
        .then(response => {
          // console.log(response.data.result.list); //请求失败返回的数据
          console.log(response.data.result);
          this.isNews = response.data.result;
        })
        .catch(error => {
          console.log(error); //请求失败返回的数据
        });
    }
  },
  mounted() {
    this.renderingNews();
    if (
      this.$route.params.isid != undefined &&
      this.$route.params.isid != " "
    ) {
      localStorage.isid = this.$route.params.isid;
      // console.log(localStorage.isid );

      this.renderingCenr(this.$route.params.isid);
      // console.log(this.$route.params.isid );
    } else {
      this.renderingCenr(localStorage.isid);
      // console.log(this.$route.params.isid );
    }
  }
};
</script>
<style lang="scss" scoped>
.isContent {
  background: #eeeeee;
  font-family: "FZLTCHJW", sans-serif;
  .artcle-wrap-pic {
    height: 560px;
    background-size: cover;
    margin-bottom: 50px;
    position: relative;
    background-position: center;
    width: 100%;
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: url(http://img6.cache.netease.com/game/img2018/play/img/img_bg.png)
        repeat-x;
      content: "";
      height: 560px;
    }
    .g-w1200 {
      width: 80%;
      margin: 0 auto;
      height: 560px;
      position: relative;
      overflow: hidden;
    }
    .article-h1 {
      bottom: 140px;
      font-size: 56px;
      color: #fff;
      line-height: 70px;
      position: absolute;
      font-weight: bold;
    }
    .art-about-new {
      font-size: 18px;
      line-height: 18px;
      color: #fff;
      overflow: hidden;
      position: absolute;
      bottom: 40px;
      width: 100%;
    }
    .m-pingce {
      float: left;
      overflow: hidden;
    }
  }
  .dontai {
    font-size: 1.6rem;
    padding: 10px;
    font-weight: bold;
  }
  .golist {
    font-size: 1.2rem;
    padding: 14.5px;
    font-weight: bold;
    color: #6b6b6b;
    text-align: right;
  }
  .centitle {
    img {
      max-width: 100%;
      height: auto;
    }
    .title {
      font-size: 1.9rem;
      padding: 14.5px;
      font-weight: bold;
      color: #282828;
      margin-top: 10px;
    }
    .time {
      font-size: 1rem;
      // font-weight: bold;
      color: #282828;
    }
    .cent {
      margin-top: 30px;
      text-align: left;
      letter-spacing: 0.5px;
      line-height: 1.5rem;
    }
  }
  .groom {
    font-size: 1rem;
    font-weight: bold;
    color: #282828;
    text-align: left;
    padding: 14.5px;
  }
  .isgroomcen {
    ul {
      //   border: 1px solid #282828;
      li {
        overflow: hidden;
        text-overflow: ellipsis;
        -ms-text-overflow: ellipsis;
        display: box;
        display: -webkit-box;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
