<template>
  <div class="about">
    <isHead></isHead>
  </div>
</template>
<script>
import isHead from "@/components/Head.vue";

export default {
  name: "about",
  components: {
    isHead
  }
};
</script>
<style lang="scss" scoped></style>
