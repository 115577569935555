<template>
  <div class="isHeadlist">
    <el-row>
      <el-col :md="12" :lg="12" :sm="8" :xs="8">
        <div style="margin:auto;margin-left:15px;margin-top:10px;">
          <img src="@/assets/logo.svg" alt height="40px" @click="gohone" />
        </div>
      </el-col>
      <el-col :md="12" :lg="12" :sm="16" v-if="isheadstyle" style="hei">
        <div>
          <ul style="   width:100%; ">
            <li class="ispcli" @click="gohone">
              <a>
                回到首页
              </a>
            </li>
          </ul>
        </div>
        <div class="line"></div>
      </el-col>
      <!-- 小屏幕显示 -->
      <el-col class :xs="16" v-if="showmove" style=" text-align: right;">
        <el-dropdown trigger="click">
          <div style=" margin-top:15px;margin-right:15px;">
            <img src="@/assets/menu.png" alt width="30px" />
          </div>
          <el-dropdown-menu slot="dropdown" style="width:98%; left:7px;">
            <el-dropdown-item @click="gohone">回到首页</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: "isHeadlist",
  data() {
    return {
      activeIndex: "1",
      isheadstyle: true,
      showmove: false
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    //获取宽 判断
    getwidht() {
      if (window.innerWidth < 768) {
        // console.log(window.innerWidth);
        this.isheadstyle = false;
        this.showmove = true;
      } else {
        this.isheadstyle = true;
        this.showmove = false;
      }
    },
    gohone() {
      this.$router.push({ path: "/" });
      // this.$router.go(-1)
    },
    jump(index) {
      // console.log(index);
      const returnEle = document.querySelector("#anchor-" + index);
      if (returnEle) {
        returnEle.scrollIntoView({ behavior: "smooth" });
      }
    },
    custormAnchor(anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        let total = anchorElement.offsetTop; //定位锚点

        //开始滚动
        // Chrome
        document.body.scrollTop = total;
        // Firefox
        document.documentElement.scrollTop = total;
        // Safari
      }
    }
  },
  created() {
    window.addEventListener("resize", this.getwidht);
    // this.getwidht()
  },

  destroyed() {
    window.removeEventListener("resize", this.getwidht);
  },
  mounted() {
    this.getwidht();
  }
};
</script>
<style lang="scss" scoped>
// 小于768 显示手机端
.isHeadlist {
  background: #ff4949;
  height: 60px;

  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.el-menu-item {
  font-weight: bold;
  font-family: "FZLTCHJW", sans-serif;
  font-size: 16px;
  :hover {
    border-bottom: solid 12px #e6e6e6;
  }
}
.el-menu.el-menu--horizontal {
  border-bottom: solid 0px #e6e6e6;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background: #ff4949;
  color: #fff;
}

.ispcli {
  list-style-type: none;
  float: left;
  // padding:10px;
  line-height: 60px;
  margin-top: -20px;
  font-weight: bold;
  font-family: "FZLTCHJW", sans-serif;
  font-size: 16px;
  height: 62px;
  padding: 0px 15px;
  text-align: center;
  a {
    color: #fff;
    text-decoration: none;
  }
  color: #fff;
  &:hover {
    border-bottom: solid 2px #e6e6e6;
    background: #bd3737;
  }
}
</style>
