<template>
  <div class="islist">
    <isHeadlist></isHeadlist>
    <!-- 我们的团队 -->
    <el-row
      style="padding:10px;"
      type="flex"
      class="row-bg"
      justify="center"
      :gutter="15"
    >
      <el-col :xs="24" :sm="24" :md="17" :lg="17" :xl="17" justify="center">
        <el-col :span="24">
          <div class="gamstetit">
            <div class="en">NEWS</div>
            <div class="zh">新闻资讯</div>
          </div>
        </el-col>
        <el-col
          :span="24"
          v-for="(item, index) in isNews"
          :key="index"
          style="margin-bottom:20px;"
        >
          <el-row>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <div style="height:187px;" @click="gocent(item.id)">
                <img :src="item.thumb" alt width="100%" height="100%;" />
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
              <div class="listcent" @click="gocent(item.id)">
                <div class="text">{{ item.title }}</div>
                <div class="abstract">
                  {{ item.description }}
                </div>
                <div class="time">
                  <!-- 08 16 2019 -->
                  {{ (item.add_time * 1000) | formatDate }}
                  <div style="float:right">
                    <el-button
                      style="border: 1px solid  #ff3f40;color:#000;background:#fff; border-radius:0px;"
                      >了解更多</el-button
                    >
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-col>
    </el-row>
    <isFooter></isFooter>
  </div>
</template>
<script>
import isFooter from "@/components/Footer.vue";
import isHeadlist from "@/components/Headlist.vue";
import { formatDate } from "@/common/date.js";

export default {
  name: "islist",
  components: {
    isHeadlist,
    isFooter
  },
  filters: {
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    }
  },
  data() {
    return {
      isNews: [],
      isurl: "https://www.sparkgame.com.cn/"
    };
  },
  methods: {
    //跳转到栏目内容
    gocent(id) {
      // console.log(1);
      this.$router.push({ name: "isContent", params: { isid: id } });
    },
    //渲染最新列表
    renderingNewscen() {
      this.$axios({
        method: "post",
        url: "https://www.sparkgame.com.cn/api.php",
        data: this.qs.stringify({
          act: "get_list",
          mode: "news"
        })
      })
        .then(response => {
          // console.log(response.data.result.list); //请求失败返回的数据
          console.log(response.data.result);
          this.isNews = response.data.result;
        })
        .catch(error => {
          console.log(error); //请求失败返回的数据
        });
    }
  },
  mounted() {
    this.renderingNewscen();
  }
};
</script>
<style lang="scss" scoped>
.islist {
  background: #eeeeee;
  .gamstetit {
    width: 100%;
    margin: 2rem 0px;
    .en {
      font-size: 1rem;
      color: #101010;
      font-family: "Helvetica", sans-serif;
    }
    .zh {
      font-size: 1.5rem;
      color: #101010;
      font-family: "FZLTCHJW", sans-serif;
      font-weight: bold;
    }
  }
  .listcent {
    text-align: left;
    background: #fff;
    padding: 10px;
    height: 167px;
    .text {
      font-size: 1.3rem;
      font-family: "FZLTCHJW", sans-serif;
      font-weight: bold;
    }
    .abstract {
      padding-top: 10px;
      font-size: 0.9rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 35px;
    }
    .time {
      border-top: 1px solid #e7e7e7;
      font-size: 0.9rem;
      // padding-top: 20px;
      font-family: "FZLTCHJW", sans-serif;
      font-weight: bold;
      line-height: 4rem;
    }
  }
}
</style>
