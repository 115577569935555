import { render, staticRenderFns } from "./Headlist.vue?vue&type=template&id=5732c663&scoped=true&"
import script from "./Headlist.vue?vue&type=script&lang=js&"
export * from "./Headlist.vue?vue&type=script&lang=js&"
import style0 from "./Headlist.vue?vue&type=style&index=0&id=5732c663&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5732c663",
  null
  
)

export default component.exports